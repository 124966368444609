<template>
  <v-container fluid class="pr-0 pl-0">
    <v-row>
      <v-col cols="12" xs="12" sm="12" md="12" class="elevation-0">
        <v-card elevation="0" rounded class="mb-3" v-if="state.isSelected">
          <v-card-actions>
            <v-spacer></v-spacer>
            <pcc-button-tooltip
              v-on:click="actions.unselectItem()"
              icon="mdi-undo"
              position="bottom"
              tooltip="Discard user changes and return to list !"
              :disabled="state.loading"
            />
            <pcc-button-tooltip
              v-if="state.isModified"
              v-on:click="actions.saveItem()"
              icon="mdi-check-all"
              position="bottom"
              tooltip="Save user data"
              :loading="state.loading"
              class="primary--text"
            />
          </v-card-actions>
          <v-card-text class="pa-5">
            <v-form ref="form" lazy-validation>
              <span class="subtitle-1 font-weight-light">Name</span>
              <v-text-field
                :value="state.item.name"
                @input="actions.updateItemAttribute({ key: 'name', value: $event })"
                :counter="30"
                :rules="[
                  (v) => !!v || $t('commons.rules.errorRequired'),
                  (v) => (v && v.length <= 30) || $t('commons.rules.errorMaxLength', { count: 30 }),
                ]"
                required
                clearable
                solo-inverted
                flat
                dense
              />
              <span class="subtitle-1 font-weight-light">E-mail</span>
              <v-text-field
                :value="state.item.email"
                @input="actions.updateItemAttribute({ key: 'email', value: $event })"
                :rules="[
                  (v) => !!v || $t('commons.rules.errorRequired'),
                  (v) => RegexTypes.EmailRegex.test(v) || $t('commons.rules.errorInvalidEmail'),
                ]"
                required
                clearable
                solo-inverted
                flat
                dense
              />
              <span v-if="state.uniqueEmailError" class="red--text"
                >Email address already in use. Please try another one.</span
              >
              <br />
              <br />
              <span v-if="state.passwordForgotten.error" class="red--text"
                >Error changing password, please try again.</span
              >
              <span v-if="state.passwordForgotten.success" class="primary--text"
                >Password has been changed successfully.</span
              >
              <v-btn
                v-if="state.item._id"
                class="elevation-0 font-weight-regular mb-3"
                block
                :loading="state.passwordForgotten.isLoading"
                v-on:click="actions.resetPassword(state.item.email)"
              >
                reset password
              </v-btn>
              <span class="subtitle-1 font-weight-light">Roles</span>
              <v-list v-for="(route, index) in routes" :key="index" subheader dense>
                <v-subheader>
                  <v-icon v-text="route.icon"></v-icon>
                  <span class="subtitle-2 font-weight-light text--secondary ml-3">{{
                    route.name
                  }}</span>
                </v-subheader>
                <v-list-item>
                  <v-list-item-avatar>
                    <v-switch
                      v-model="state.item.roles[route.path]"
                      @change="
                        actions.updateItemAttribute({ key: `roles.${route.path}`, value: $event })
                      "
                    />
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title
                      class="font-weight-light text--secondary"
                      v-text="$t('commons.operation.canSee')"
                    />
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-form>
          </v-card-text>
          <v-dialog
            v-model="state.passwordForgotten.showTokenModal"
            class="ma-16 max-z-index"
            max-width="450px"
            min-width="400px"
            persistent
          >
            <two-fa-token-modal
              @canceling="handleTwoFaCancel"
              @token="(token) => handleTwoFaToken(token, state.item.email)"
              :error-msg="state.passwordForgotten.twoFaTokenInvalidMsg"
              :is-validating-token="state.passwordForgotten.twoFaIsValidatingToken"
            />
          </v-dialog>
          <v-card-actions>
            <v-spacer></v-spacer>
            <pcc-button-tooltip
              v-on:click="actions.unselectItem()"
              icon="mdi-undo"
              position="bottom"
              tooltip="Discard user changes and return to list !"
              :disabled="state.loading"
            />
            <pcc-button-tooltip
              v-if="state.isModified"
              v-on:click="actions.saveItem()"
              icon="mdi-check-all"
              position="bottom"
              tooltip="Save user data"
              :loading="state.loading"
              class="primary--text"
            />
          </v-card-actions>
        </v-card>
        <v-card elevation="0" rounded class="mb-3" v-else>
          <v-toolbar dense flat>
            <v-spacer />
            <pcc-button-tooltip
              v-if="state.items && state.items.length < 4"
              v-on:click="
                actions.newItem({
                  name: '',
                  email: '',
                  roles: {
                    dashboard: true,
                    offers: true,
                    reports: true,
                    conversionReport: true,
                    billing: true,
                    users: false,
                    my: true,
                    smartlinks: false,
                    support: false,
                  },
                })
              "
              icon="mdi-plus"
              position="bottom"
              tooltip="Add a new user to account"
            />
          </v-toolbar>
          <v-data-table
            :headers="headers"
            :items="state.loading ? [] : state.items"
            :loading="state.loading"
            class="pl-5 pr-5"
          >
            <template v-slot:item.actions="{ item }">
              <pcc-button-edit-delete
                v-on:edit="actions.selectItem({ value: item })"
                v-on:delete="actions.deleteItem({ value: item })"
              ></pcc-button-edit-delete>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { inject, onMounted } from '@vue/composition-api';
import AffiliateUser from '../../store/private/AffiliateUser';
import PccButtonTooltip from '../Components/PccButtonTooltip';
import PccButtonEditDelete from '../Components/PccButtonEditDelete';
import CropperComponent from '../Components/CropperComponent';
import TwoFaTokenModal from '../Components/TwoFaTokenModal.vue';
import RegexTypes from '../../store/constants/RegexTypes';

export default {
  components: {
    'cropper-component': CropperComponent,
    'pcc-button-tooltip': PccButtonTooltip,
    'pcc-button-edit-delete': PccButtonEditDelete,
    TwoFaTokenModal,
  },
  data() {
    return {};
  },
  setup(props, context) {
    const application = inject('Application');
    const store = AffiliateUser(props, context);
    onMounted(async () => {
      await store.actions.applyFilter({ _affiliate: application.state.user._id });
    });
    const headers = [
      // { text: 'ID', value: '_id', align: 'start', width: 100 },
      { text: 'Name', value: 'name', align: 'start', width: 300, sortable: true },
      { text: 'Email', value: 'email', align: 'start', width: 300, sortable: true },
      { text: 'Actions', value: 'actions', align: 'center', width: 100, sortable: false },
    ];
    return { headers, ...store, routes: application.state.reducedRoutes, RegexTypes };
  },
  methods: {
    closeTwoFaTokenModal() {
      this.state.passwordForgotten.showTokenModal = false;
    },
    handleTwoFaCancel() {
      this.closeTwoFaTokenModal();
      this.actions.resetPasswordForgottenState();
    },
    handleTwoFaToken(token, email) {
      this.actions.resetPassword(email, token);
    },
  },
};
</script>
<style lang="scss">
.v-avatar--offset {
  top: -38px;
  position: relative;
}
.full-width {
  width: 100%;
}
</style>
